import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overline" }
const _hoisted_2 = { class: "ion-text-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Date time parse error")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.translate("File upload")), 1),
                _createElementVNode("h1", null, _toDisplayString(_ctx.translate("Date format")), 1),
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translate("Enter a custom date time format that you want to use when uploading documents to HotWax Commerce.")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_input, {
              "clear-input": true,
              modelValue: _ctx.dateTimeFormat,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateTimeFormat) = $event)),
              value: _ctx.dateTimeFormat
            }, null, 8, ["modelValue", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.sampleDateTime), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_badge, { color: "warning" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Sample")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_button, {
          class: "ion-margin-top ion-margin-start",
          fill: "outline",
          onClick: _ctx.parseSampleDateTime
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("Check sample")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { color: "medium" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("This will update items across POs", {numberOfItems: _ctx.numberOfItems, numberOfPos: _ctx.numberOfPos})), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              disabled: !(_ctx.dateTimeFormat && _ctx.dateTimeFormat !== _ctx.currentDateTimeFormat),
              onClick: _ctx.updateDateTimeFormat
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}