import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!

  return (_ctx.imageUrl)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.imageUrl
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
        key: 1,
        animated: ""
      }))
}