import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e5a4813"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "search" }
const _hoisted_3 = { class: "filters" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "ion-text-center"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_PurchaseOrderDetail = _resolveComponent("PurchaseOrderDetail")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/purchase-order"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Review PO details")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectAllItems(_ctx.segmentSelected)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.checkboxOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.revertAll()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.arrowUndoOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_searchbar, {
                placeholder: _ctx.translate('Search products'),
                onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.searchProduct(_ctx.queryString)}, ["enter"]))
              }, null, 8, ["placeholder"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ion_item, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openBulkAdjustmentModal())),
                button: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.calculatorOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Bulk adjustment")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getSelectedItems()) + " " + _toDisplayString(_ctx.translate("items selected")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    icon: _ctx.chevronForwardOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDateTimeParseErrorModal())),
                button: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.timeOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Date time parse error")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getItemsWithInvalidDateFormat()) + " " + _toDisplayString(_ctx.translate("items")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    icon: _ctx.chevronForwardOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openMissingFacilitiesModal())),
                button: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.businessOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Missing facilities")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getItemsWithMissingFacility().length) + " " + _toDisplayString(_ctx.translate("items")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    mode: "ios",
                    icon: _ctx.chevronForwardOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openMissingSkuModal())),
                button: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.shirtOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Missing products")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.purchaseOrders.unidentifiedItems?.length) + " " + _toDisplayString(_ctx.translate("items")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    icon: _ctx.chevronForwardOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ])
          ]),
          (_ctx.searchedProduct?.pseudoId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_PurchaseOrderDetail, {
                  purchaseOrders: _ctx.purchaseOrders,
                  itemsByPoId: {[_ctx.searchedProduct?.orderId]: [_ctx.searchedProduct]}
                }, null, 8, ["purchaseOrders", "itemsByPoId"])
              ]))
            : (_ctx.queryString)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.translate("No results found")), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.segmentSelected === 'all')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_PurchaseOrderDetail, {
                          purchaseOrders: _ctx.purchaseOrders,
                          itemsByPoId: _ctx.purchaseOrders.parsed
                        }, null, 8, ["purchaseOrders", "itemsByPoId"])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.purchaseOrders.parsed, (po, poId) => {
                    return (_openBlock(), _createElementBlock("div", { key: poId }, [
                      (_ctx.segmentSelected === poId)
                        ? (_openBlock(), _createBlock(_component_PurchaseOrderDetail, {
                            key: 0,
                            itemsByPoId: {[poId]: po},
                            purchaseOrders: _ctx.purchaseOrders
                          }, null, 8, ["itemsByPoId", "purchaseOrders"]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                disabled: _ctx.isDateInvalid(),
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.cloudUploadOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_segment, {
            scrollable: "true",
            onIonChange: _cache[7] || (_cache[7] = ($event: any) => {_ctx.selectAllItems($event.target.value); _ctx.searchProduct(_ctx.queryString);}),
            modelValue: _ctx.segmentSelected,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.segmentSelected) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_segment_button, { value: "all" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("All")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.purchaseOrders.parsed, (po, poId) => {
                return (_openBlock(), _createBlock(_component_ion_segment_button, {
                  key: poId,
                  value: poId
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(poId), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}