import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Bulk adjustment")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { lines: "full" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.translate('Buffer days'),
                  modelValue: _ctx.bufferDays,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bufferDays) = $event)),
                  type: "number",
                  placeholder: _ctx.translate('Lead time'),
                  min: "1"
                }, null, 8, ["label", "modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "full" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.translate('Order buffer'),
                  modelValue: _ctx.orderBuffer,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orderBuffer) = $event)),
                  type: "number",
                  placeholder: _ctx.translate('Safety stock'),
                  min: "1"
                }, null, 8, ["label", "modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select, {
                  label: _ctx.translate('Catalog'),
                  interface: "popover",
                  modelValue: _ctx.isNewProduct,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isNewProduct) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select_option, { value: "N" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Backorder")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_select_option, { value: "Y" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Preorder")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select, {
                  label: _ctx.translate('Facility'),
                  interface: "popover",
                  modelValue: _ctx.facilityId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.facilityId) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilities, (facility) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: facility.facilityId,
                        value: facility.facilityId
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(facility.facilityName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, { onClick: _ctx.save }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}