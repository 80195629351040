import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ion-padding-top actions desktop-only" }
const _hoisted_2 = { class: "ion-padding-top actions mobile-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_input, {
          label: _ctx.translate('Mapping name'),
          modelValue: _ctx.currentMapping.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentMapping.name) = $event))
        }, null, 8, ["label", "modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFields(), (fieldValues, field) => {
          return (_openBlock(), _createBlock(_component_ion_item, { key: field }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                label: _ctx.translate(fieldValues.label),
                modelValue: _ctx.currentMapping.value[field],
                "onUpdate:modelValue": ($event: any) => ((_ctx.currentMapping.value[field]) = $event)
              }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_ion_button, {
          size: "small",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateMapping()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.saveOutline
            }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.translate("Save Changes")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_button, {
          size: "small",
          fill: "outline",
          color: "danger",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteMapping()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.trashOutline
            }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.translate("Delete mapping")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_button, {
        expand: "block",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateMapping()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.saveOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("Save Changes")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_button, {
        fill: "outline",
        color: "danger",
        expand: "block",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteMapping()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.trashOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("Delete mapping")), 1)
        ]),
        _: 1
      })
    ])
  ]))
}