import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c41d8374"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.closeOutline,
                        slot: "icon-only"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Missing SKUs")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ion_item, {
              id: "update-sku",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.updatedSku,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.updatedSku) = $event)),
                  "clear-input": true,
                  placeholder: _ctx.translate('Select SKU'),
                  onIonFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectInputText($event))),
                  onKeyup: _withKeys(_ctx.update, ["enter"]),
                  class: _normalizeClass(_ctx.isSkuInvalid ? 'ion-touched ion-invalid' : ''),
                  "error-text": _ctx.translate('This SKU is not available, please try again')
                }, null, 8, ["modelValue", "placeholder", "onKeyup", "class", "error-text"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              onClick: _ctx.update,
              disabled: !(_ctx.unidentifiedProductSku && _ctx.updatedSku)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Update")), 1)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]),
          _createVNode(_component_ion_segment, {
            modelValue: _ctx.segmentSelected,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.segmentSelected) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_segment_button, { value: "pending" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Pending")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_segment_button, { value: "completed" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Completed")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_ion_radio_group, {
            onIonChange: _cache[3] || (_cache[3] = ($event: any) => {_ctx.updatedSku = $event.detail.value; _ctx.hasSkuUpdated = false; _ctx.isSkuInvalid = false;}),
            modelValue: _ctx.unidentifiedProductSku,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.unidentifiedProductSku) = $event))
          }, {
            default: _withCtx(() => [
              (_ctx.segmentSelected === 'pending')
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPendingItems(), (item) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: item.shopifyProductSKU
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_radio, {
                              value: item.shopifyProductSKU
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.shopifyProductSKU) + " ", 1),
                                    _createElementVNode("p", null, _toDisplayString(item.orderId), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.segmentSelected === 'completed')
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCompletedItems(), (item) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: item.shopifyProductSKU
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_DxpShopifyImg, {
                                  src: item.imageUrl,
                                  size: "small"
                                }, null, 8, ["src"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_radio, {
                              value: item.updatedSku
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_1, _toDisplayString(item.parentProductName), 1),
                                    _createTextVNode(" " + _toDisplayString(item.updatedSku) + " ", 1),
                                    _createElementVNode("p", null, _toDisplayString(item.orderId), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, { onClick: _ctx.save }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}