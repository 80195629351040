export const SN_USER = 'user'
export const USER_TOKEN_CHANGED = SN_USER + '/TOKEN_CHANGED'
export const USER_END_SESSION = SN_USER + '/END_SESSION'
export const USER_INFO_UPDATED = SN_USER + '/INFO_UPDATED'
export const USER_CURRENT_FACILITY_UPDATED = SN_USER + '/CURRENT_FACILITY_UPDATED'
export const USER_INSTANCE_URL_UPDATED = SN_USER + '/INSTANCE_URL_UPDATED'
export const USER_PWA_STATE_UPDATED = SN_USER + '/PWA_STATE_UPDATED'
export const USER_DATETIME_FORMAT_UPDATED = SN_USER + '/DATETIME_FORMAT_UPDATED'
export const USER_CURRENT_FIELD_MAPPING_UPDATED = SN_USER + '/_CURRENT_FIELD_MAPPING_UPDATED'
export const USER_FIELD_MAPPINGS_UPDATED = SN_USER + '/FIELD_MAPPINGS_UPDATED'
export const USER_FIELD_MAPPING_CREATED = SN_USER + '/FIELD_MAPPING_CREATED'
export const USER_PERMISSIONS_UPDATED = SN_USER + '/PERMISSIONS_UPDATED'
